import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userProvider";
import { toast } from "react-toastify";
import Input from "./inputSala";
import TitleDetails from "./TitlteDetails";
import Modal from "./Modal";
import "./styles.css";


export default function NovaSala() {
  const {
    prefId,
    salaId,
    setSalaId,
    setPrefId,
    setUserConfig,
    isLoading,
    setIsLoading,
    showModal,
    setShowModal,
  } = useContext(UserContext);
  const [nomeSala, setNomeSala] = useState("");
  const [linkSala, setLinkSala] = useState("");
  const [customUserId, setCustomUserId] = useState("");


  useEffect(() => {
    const id = window.location.pathname.split("/")[2];
    setPrefId(id);
    document.cookie = `prefId=${id};path=/; domain=voxcity.com.br; secure`;
    //remove userId cookie
    document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=voxcity.com.br; secure`;
  }, []);

  const handleChange = (e) => {
    setNomeSala(e.target.value);
  };

  const CriarSala = async () => {
    if (!nomeSala) {
      toast.error("Insira um nome para a sala");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
         `https://video.voxcity.com.br/criarSala`,
        //`http://localhost:5000/criarSala`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nome: nomeSala,
            id: prefId,
          }),
        }
      );
      const data = await response.json();
      if (!data.salaLink) {
        toast.error(data.error);
        setIsLoading(false);
        return;
      }
      document.cookie = `userId=${data.userId};path=/; domain=voxcity.com.br; secure`;
      setCustomUserId(data.userId);
      // document.cookie = `userId=${data.userId};path=/;`;
      setLinkSala(data.salaLink);
      setSalaId(data.salaId);
      setUserConfig({
        audioEnabled: data.audioEnabled,
        videoEnabled: data.videoEnabled,
        username: data.username,
      });
      setIsLoading(false);
      toast.success("Sala criada com sucesso!");
      setShowModal(true);
    } catch (error) {
      console.error("Erro", error);
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="home-container-sala">
      <div className="content">
        <TitleDetails />
        <Input
          nomeSala={nomeSala}
          handleChange={handleChange}
          isLoading={isLoading}
          CriarSala={CriarSala}
        />
        {showModal && (
          <Modal
            closeModal={closeModal}
            linkSala={linkSala}
            prefId={prefId}
            salaId={salaId}
            customUserId={customUserId}
          />
        )}
      </div>
    </div>
  );
}
