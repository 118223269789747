import { Route, Routes } from "react-router";
import Login from "../components/Login";
import Transmissao from "../components/Transmissao";
import Redirecionar from "../components/Redirecionar";
import NovaSala from "../components/NovaSala";
import CustomRoom from "./../components/CustomRoom";

export default function Routeror() {
  return (
    <Routes>
      <Route path="/" element={<Redirecionar />} />
      <Route path="/builder/:id" element={<NovaSala />} />
      <Route path="/:linkNome" element={<Login />} />
      <Route
        path="/customRoom/:prefId/:salaId/:linkSala/:customUserId"
        element={<CustomRoom />}
      />
      <Route path="/transmissao" element={<Transmissao />} />
    </Routes>
  );
}
